<template>
  <div>
    <h2 class="th-title">Topic</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input placeholder="Masukan nama topic" style="width: 200px" v-model:value="search" @change="handleSearchCategory">
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Tambahkan Topic
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Topic" @ok="handleOk">
          <a-form layout="vertical" :model="formAdd">
            <a-form-item required label="title" name="title" v-bind="validateInfos.name">
              <a-input placeholder="Insert Title" v-model:value="formAdd.title" />
            </a-form-item>
            <a-form-item label="Description">
              <a-textarea
                :rows="6"
                placeholder="Masukkan deskripsi (opsional)"
                v-model:value="formAdd.description"
                show-count
                :maxlength="100"
              />
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit"
              >Tambahkan</a-button
            >
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :columns="columns" :data-source="data" bordered>
          <template #name="{ text, record }">
            <a-form :model="formEdit" layout="vertical" v-if="record.key === idEdit">
              <a-form-item name="name" v-bind="validateInfosEdit.name">
                <a-input v-model:value="formEdit.name" />
              </a-form-item>
            </a-form>
            <span v-else>
              {{ text }}
            </span>
          </template>
          <template #description="{ text, record }">
            <span v-if="record.key === idEdit">
              <a-textarea v-model:value="formEdit.description" />
            </span>
            <span v-else>
              {{ text || '-' }}
            </span>
          </template>
          <template #action="{ record }">
            <span v-if="record.key === idEdit">
              <a @click.prevent="confirmEdit(record)">
                <check-outlined></check-outlined>
              </a>
              <a-divider type="vertical" />
              <a @click.prevent="closeEdit">
                <close-outlined></close-outlined>
              </a>
            </span>
            <span v-else>
              <a @click.prevent="categoryAction(record, 'ASSIGN')">Tambahkan Sub Topic</a>
              <a-divider type="vertical" />
              <a @click.prevent="categoryAction(record, 'UPDATE')">Ubah Topic</a>
              <a-divider type="vertical" />
              <a @click.prevent="categoryAction(record, 'DELETE')">Hapus Topic</a>
            </span>
            <a-modal v-model:visible="modalAssignVisible" title="Tambahkan Topic" @ok="handleOkAssign">
                <div label="Pilih Topic" name="topic">
                  <label> Pilih Topic </label>
                  <a-select class="ml-2" style="width: 100%;" v-model:value="formAssign.topic">
                    <a-select-option v-for="(topic, i) in subtopicList" :key="i" :value="topic.key">
                      <span> {{topic.title}} </span>
                    </a-select-option>
                  </a-select>
                </div>
              <template #footer>
                <a-button @click.prevent="confirmAssign" key="submit" type="primary" html-type="submit"
                  >Tambahkan</a-button
                >
              </template>
            </a-modal>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.subtopicData"
              :pagination="false"
            >
              <template #coursePrice="{ text }">
                <div v-if="record.discountPercentage" class="d-flex flex-column">
                  <del class="mr-2"
                    ><small>{{ priceFormat(text) }}</small></del
                  >
                  <span>{{ priceFormat(recordPrice.discountedPrice) }}</span>
                </div>
                <span v-else>{{ priceFormat(text) }}</span>
              </template>
            </a-table>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';

const columns = [
  {
    title: 'Judul',
    dataIndex: 'title',
    key: 'title',
    slots: { customRender: 'title' },
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
    slots: { customRender: 'description' },
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];
const innerColumns = [
  {
    title: 'Subtopic',
    dataIndex: 'title',
    key: 'title',
    slots: { customRender: 'title' },
  },
  {
    title: 'Subtopic Description',
    dataIndex: 'description',
    key: 'description',
    slots: { customRender: 'description' },
  },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let subtopicList = ref([])
    let search = ref(null)
    let modalAddVisible = ref(false)
    let modalAssignVisible = ref(false)
    let selectedTopic = -1;
    let formAdd = reactive({
      name: null,
      description: null,
    })
    let formAssign = ref({
      topic: null
    })
    let formEdit = ref({
      name: null,
      description: null,
    })
    let idEdit = ref(null)
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      title: [
        {
          required: true,
          message: 'Title harus diisi!',
          trigger: 'change',
        },
      ],
    })

    onMounted(() => {
      fetchTopic()
    })

    const fetchTopic = async (search = '') => {
      // const rawData = await store.dispatch('category/FETCH_CATEGORY', search)\
      const topics = store.state.topic.topics
      for (const i in topics) {
        if (topics[i].subtopics) {
          let subtopicData = []
          for (const j in topics[i].subtopics) {
            subtopicData.push(topics.filter(el => el.key == topics[i].subtopics[j])[0])
          }
          topics[i].subtopicData = subtopicData
        }
      }
      data.value = topics
    }

    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

    const handleOkAssign = () => {
      modalAssignVisible.value = false
    }

    const closeEdit = () => idEdit.value = null

    const { validate:validateEdit, validateInfos:validateInfosEdit } = useForm(formEdit, rulesRef);
    const confirmEdit = async (record) => {
      try {
        const payload = {
          key: record.key,
          title: formEdit.value.title,
          description: formEdit.value.description,
        }
        const tes = await validateEdit()
        // const tes2 = await validate()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada topic '${record.title}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              // const data = await store.dispatch('category/UPDATE_CATEGORY', payload)
              modalAddVisible.value = false
              console.log(payload, 'payload')
              Object.assign(store.state.topic.topics.filter(item => payload.key === item.key)[0], payload);
              fetchTopic()
              notification.success({
                message: `Berhasil Mengubah Data Pada Topic '${record.title}'`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const confirmAssign = async (record) => {
      try {
        console.log(formAssign.value.topic)
        Modal.confirm({
            title: 'Apa anda yakin ingin menambahkan sub topic?',
            icon: createVNode(ExclamationCircleOutlined),
            async onOk() {
              try {
                // const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
                let targetTopic = store.state.topic.topics.filter(item => selectedTopic === item.key)[0]
                const isSubtopicAlreadyExist = targetTopic.subtopics.filter(el => el == formAssign.value.topic)
                if (isSubtopicAlreadyExist.length > 0) return Modal.error({
                    title: 'Subtopic already exist',
                    content: 'Sub Topic tidak bisa duplikat.',
                  })
                targetTopic.subtopics.push(formAssign.value.topic)
                modalAssignVisible.value = false
                fetchTopic()
                notification.success({
                  message: `Berhasil Menambahkan ke daftar Sub Topic`,
                })
                resetFields()
              } catch (err) {
                console.log(err)
              }
            },
            onCancel() {},
          })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

    const confirmAdd = async () => {
      try {
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan topic?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              // const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
              store.state.topic.topics.push(formAdd)
              modalAddVisible.value = false
              fetchTopic()
              notification.success({
                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Topic`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {}
    }

    const categoryAction = async (record, action) => {
      const data = {
        id: record.key,
        data: {
          name: record.name,
          description: record.description,
        },
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus topic '${record.title}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              // const res = await store.dispatch('category/DELETE_CATEGORY', { ...data })
              fetchTopic()
              notification.success({
                message: `Berhasil Menghapus '${record.title}' Dari Daftar Topic`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } else if (action === 'UPDATE') {
        idEdit.value  = record.key
        formEdit.value = {
          title: record.title,
          description: record.description || null,
        }
        // const data = await store.dispatch(action + '_CATEGORY', { id, data })
      } else if (action === 'ASSIGN') {
        modalAssignVisible.value = true
        selectedTopic = record.key
        subtopicList.value = store.state.topic.topics.filter(el => el.key != selectedTopic)
      }
    }

    const handleSearchCategory = () => {
      fetchCategory(search.value)
    }

    return {
      data,
      columns,
      search,
      modalAddVisible,
      confirmAssign,
      showModalAdd,
      handleOk,
      formAdd,
      rulesRef,
      loadingAdd,
      confirmAdd,
      validate,
      validateInfos,
      validateEdit,
      validateInfosEdit,
      categoryAction,
      modalAssignVisible,
      handleOkAssign,
      idEdit,
      formEdit,
      formAssign,
      closeEdit,
      confirmEdit,
      handleSearchCategory,
      innerColumns,
      selectedTopic,
      subtopicList,
    }
  },
}
</script>
